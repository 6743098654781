import React from "react"
import { Helmet } from "react-helmet"

export default function ToptierEn({ location, data }) {
  return (
    <>
      <Helmet title="Ecomaxx | Toptier">
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link
          rel="stylesheet"
          href="/toptier/css/bootstrap.min.css"
          integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm"
          crossorigin="anonymous"
        />
        <link rel="stylesheet" href="/toptier/css/styles.css" />
      </Helmet>

      <header class="header">
        <nav class="navbar">
          <a class="logo" href="/">
            EcoMaxx
          </a>
          <a class="btn" href="/toptier">
            ESPAÑOL
          </a>
        </nav>
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-6">
              <h1>The highest standard of cleanliness in your engine</h1>
            </div>
          </div>
        </div>
      </header>
      <section class="intro text-center py-5">
        <div class="container">
          <img
            class="pb-5"
            src="/toptier/images/logo-toptier.svg"
            alt="TOP TIER - Detergent Gasoline"
          />
          <h2>
            EcoMaxx is a TOP TIER™ certified gasoline brand, that is recognized
            as a premier fuel performance specification developed and enforced
            by leading automotive and heavy-duty equipment manufacturers. TOP
            TIER™ is recommended by the following:
          </h2>
        </div>
      </section>
      <section class="tabla pt-5">
        <div class="container">
          <p class="text-center">TOP TIER™ is recommended by the following:</p>
          <table class="table table-striped">
            <tbody>
              <tr>
                <td>Audi</td>
                <td>BMW</td>
                <td>GM</td>
                <td>FCA</td>
              </tr>
              <tr>
                <td>Ford</td>
                <td>Honda</td>
                <td>Toyota</td>
                <td>Mercedes-Benz</td>
              </tr>
              <tr>
                <td>Navistar</td>
                <td>VW</td>
                <td>Detroit Diesel</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      <section class="cards pt-5">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="card h-100 text-center">
                <div class="card-header">
                  <h3>Approvals</h3>
                </div>
                <div class="card-body">
                  <img
                    class="py-3"
                    src="/toptier/images/icon-approvals.svg"
                    alt="Approvals"
                  />
                  <p>EcoMaxx has PowerMaxx additive detergent.</p>
                  <p>
                    PowerMaxx is a certified detergent conforming to the U.S.
                    Environmental Protection Agency’s requirements for the
                    lowest additive concentration (LAC) at 24 ptb. (40CFR part
                    80 subpart G)
                  </p>
                  <p>
                    PowerMaxx meets the TOP TIER™ gasoline deposit control
                    additive requirements as outlined in the TOP TIER™ DEPOSIT
                    CONTROL STANDARD at a treat rate of 58 ptb.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 pt-5 pt-md-0">
              <div class="card h-100 text-center">
                <div class="card-header">
                  <h3>Application</h3>
                </div>
                <div class="card-body">
                  <img
                    class="py-3"
                    src="/toptier/images/icon-application.svg"
                    alt="Application"
                  />
                  <p>
                    PowerMaxx is a multipurpose synthetic detergent which
                    imparts the following properties into gasoline:
                  </p>
                  <ul class="text-left">
                    <li>Intake valve deposit control</li>
                    <li>Combustion chamber deposit control</li>
                    <li>
                      Gasoline Direct Injection (GDI) keep clean and clean-up
                      performance
                    </li>
                    <li>PFI detergency</li>
                    <li>Rust protection</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="propiedades py-5">
        <div class="container">
          <div class="content py-5 px-3">
            <h3 class="text-center pb-3">Typical Properties</h3>
            <div class="propiedad">
              <p>Appearence</p>
              <p>Clear liquid</p>
            </div>
            <div class="propiedad">
              <p>Specific Gravity , 0/60°F (15.6/15.6°C)</p>
              <p>0.868</p>
            </div>
            <div class="propiedad">
              <p>Density, lb/gal, 60°F, (15.6°C)</p>
              <p>724</p>
            </div>
            <div class="propiedad">
              <p>Flash Point, PMCC, °F (°C)</p>
              <p>101 (38.3)</p>
            </div>
            <div class="propiedad">
              <p>Pour Point, °F (°C)</p>
              <p>40 (40)</p>
            </div>
            <div class="propiedad">
              <p>Viscosity, cSt @</p>
              <div class="propiedad">
                <p>104°F (40°C)</p>
                <p>14.0</p>
              </div>
            </div>
            <div class="propiedad">
              <p></p>
              <div class="propiedad">
                <p>-4°F (-20°C)</p>
                <p>217</p>
              </div>
            </div>
            <div class="propiedad">
              <p></p>
              <div class="propiedad">
                <p>-22°F (-30°C)</p>
                <p>264</p>
              </div>
            </div>
            <div class="propiedad mt-3">
              <p>Vapor Pressure, psi @</p>
              <div class="propiedad">
                <p>77°F (25°C)</p>
                <p>0.029</p>
              </div>
            </div>
            <div class="propiedad">
              <p></p>
              <div class="propiedad">
                <p>120°F (49°C)</p>
                <p>0.1</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer class="footer text-center">
        <img src="/toptier/images/logo-ecomaxx.svg" alt="Ecomaxx" />
      </footer>

      <script
        src="/toptier/js/jquery-3.2.1.slim.min.js"
        integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN"
        crossorigin="anonymous"
      ></script>
      <script
        src="/toptier/js/bootstrap.min.js"
        integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl"
        crossorigin="anonymous"
      ></script>
      <script src="/toptier/js/scripts.js"></script>
    </>
  )
}
